import axiosLib from 'axios';

function errorResponseHandler(error) {

    // check for errorHandle config
    if (error.config.hasOwnProperty('errorHandle') || error.config.errorHandle === false) {
        return Promise.reject(error);
    }
    // if has response show the error
    switch (error.response.status) {
        // case 400:
        case 401:
            localStorage.setItem('token', '');
            window.location.href = '/login';
            break;
        case 402:
        // localStorage.clear();
        // window.location.reload()
        break;
        default:
            break;
    }
    throw error;
}

const instance =  axiosLib.create({
    headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}, "Content-Type": "application/json",
})

instance.interceptors.response.use(
    response => response,
    errorResponseHandler
);

export default instance;