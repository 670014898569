import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Navbar from './components/navbar';
import Signup from './components/signup';
import Login from './components/login';
import Service from './components/newService';
import Landing from './components/landing';
import History from './components/history';
import Confirmation from './components/confirmation';
import ChangePassword from './components/changePassword';
import theme from "./theme";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import './App.css';

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <div className="App" style={localStorage.getItem("lang") === "ar"?{direction: 'rtl'}: {direction: 'ltr'}}>
            <Route component={Navbar} />
            <div>
              <Route exact path="/" component={Landing} />
              <Route exact path="/newService" component={Service} />
              <Route exact path="/history" component={History} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/signup" component={Signup} />
              <Route exact path="/confirmation/:token" component={Confirmation} />
              <Route exact path="/changePassword/:token" component={ChangePassword} />
            </div>
          </div>
        </BrowserRouter>
      </MuiThemeProvider>
    );
  }
}

export default App;
