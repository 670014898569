import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


//change to backend url:
localStorage.setItem('baseURL', 'https://koraik-server.onrender.com');
// localStorage.setItem('baseURL', 'http://localhost:5000');
if (localStorage.getItem('lang') === null) {
    localStorage.setItem('lang', 'ar')
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
