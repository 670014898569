import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  direction: localStorage.getItem('lang') === 'ar'? 'rtl': 'ltr',
  palette: {
    primary: {
      light: "#1d3768",
      main: "#1d3768",
      dark: "#1d3768",
      contrastText: "#1d3768"
    },
    secondary: {
      light: "#fdb715",
      main: "#fdb715",
      dark: "#fdb715",
      contrastText: "#2c2d72"
    },
    redClicked: {
      backgroundColor: "#da0e2f",
      color: "#FFFFFF",
      width: '100%',
      borderWidth: 3
    },
    redNotClicked: {
      backgroundColor: "#E8E8E8",
      color: "#da0e2f",
      width: '100%'
    },
    greenClicked: {
      backgroundColor: "#2b6b04",
      color: "#FFFFFF",
      width: '100%',
      borderWidth: 3
    },
    greenNotClicked: {
      backgroundColor: "#E8E8E8",
      color: "#2b6b04",
      width: '100%',
    },
  },
  typography: {
    useNextVariants: true,
    suppressDeprecationWarnings: true, //fixes typography error leak. Wooow!!
  }
});