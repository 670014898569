import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import Loader from './loader';
import swal from 'sweetalert';
import language from '../language.js';

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      loader: false
    }
  }
  handleChange(field, evt) {
    if (evt.target.name === "phoneNumber") {
      const re = /^\d*$/;
      if (evt.target.value === '' || re.test(evt.target.value)) {
        this.setState({ [field]: evt.target.value })
      }
    }
    else {
      this.setState({
        [field]: evt.target.value
      })
    }

  }
  validate() {
    if (this.state.email !== "" || this.state.password !== "" || this.state.password !== "" || this.state.password !== "" || this.state.lastName !== "" || this.state.firstName !== "") {
      if (this.state.email.includes('@') && this.state.email.includes('.')) {
        if (this.state.password === this.state.confirmPassword && this.state.password.length > 7 && this.state.confirmPassword.length > 7) {
          if (this.state.phoneNumber.length === 11) {
            return
          }
          else {
            return { phoneNumber: language.phoneNumberError[localStorage.getItem('lang')] }
          }
        }
        else {
          return { password: language.passwordError[localStorage.getItem('lang')] }
        }
      }
      else {
        return { email: language.emailError[localStorage.getItem('lang')] }
      }
    } else {
      return { fields: language.fillAllFieldsError[localStorage.getItem('lang')] }
    }
  }
  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSubmit();
    }
  }
  handleSubmit() {
    let issue = this.validate()
    if (issue) {
      swal(Object.values(issue)[0])
    } else {
      this.setState({ loader: true })
      let url = `${localStorage.getItem('baseURL')}/api/user/register`;
      const obj = {
        'email': this.state.email.toLowerCase(),
        'password': this.state.password,
        'firstName': this.state.firstName,
        'lastName': this.state.lastName,
        'phoneNumber': this.state.phoneNumber,
        'fromDashboard': false
      };
      axios.post(url, obj)
        .then((response) => {
          console.log(response.response)
          this.setState({ loader: false })
          swal('Account created', response.data.message, 'success')
            .then(() => this.props.history.push(`/`))
        }).catch((error) => {
          console.log(error.response)
          this.setState({ loader: false })
          swal('Error', error.response.data.message, 'error');
          console.log(error);
        })
    }
  }

  render() {
    return (
      <div className="app-addpost">
        <Grid container spacing={24} justify="center" alignItems="center" style={{margin: 0, width: '100%' }}>
          <Grid item xs={12} md={6}>
            <Paper className="paper" style={{ padding: 20, position: 'relative', marginTop: 20 }}>
              <div className="login-logo">
                <img src="/logo.png" alt="logo" />
              </div>
              <Grid container spacing={24} justify="center" alignItems="center">
                <Grid item xs={12} md={12}>
                  <TextField
                    id="email"
                    placeholder={language.email[localStorage.getItem('lang')]}
                    value={this.state.email}
                    margin="normal"
                    variant="outlined"
                    name="username"
                    type="textarea"
                    fullWidth
                    onKeyPress={(e) => this.handleKeyPress(e)}
                    onChange={this.handleChange.bind(this, 'email')} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="firstName"
                    placeholder={language.firstName[localStorage.getItem('lang')]}
                    value={this.state.firstName}
                    margin="normal"
                    variant="outlined"
                    name="firstName"
                    type="textarea"
                    fullWidth
                    onKeyPress={(e) => this.handleKeyPress(e)}
                    onChange={this.handleChange.bind(this, 'firstName')} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="lastName"
                    placeholder={language.lastName[localStorage.getItem('lang')]}
                    value={this.state.lastName}
                    margin="normal"
                    variant="outlined"
                    name="lastName"
                    type="textarea"
                    fullWidth
                    onKeyPress={(e) => this.handleKeyPress(e)}
                    onChange={this.handleChange.bind(this, 'lastName')} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="password"
                    placeholder={language.password[localStorage.getItem('lang')]}
                    value={this.state.password}
                    margin="normal"
                    variant="outlined"
                    name="password"
                    type="password"
                    fullWidth
                    onKeyPress={(e) => this.handleKeyPress(e)}
                    onChange={this.handleChange.bind(this, 'password')} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="confirmPassword"
                    placeholder={language.confirmPassword[localStorage.getItem('lang')]}
                    value={this.state.confirmPassword}
                    margin="normal"
                    variant="outlined"
                    name="confirmPassword"
                    type="password"
                    fullWidth
                    onKeyPress={(e) => this.handleKeyPress(e)}
                    onChange={this.handleChange.bind(this, 'confirmPassword')} />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="phoneNumber"
                    placeholder={language.phoneNumber[localStorage.getItem('lang')]}
                    value={this.state.phoneNumber}
                    margin="normal"
                    variant="outlined"
                    name="phoneNumber"
                    inputProps={{
                      maxLength: 11
                    }}
                    fullWidth
                    onKeyPress={(e) => this.handleKeyPress(e)}
                    onChange={this.handleChange.bind(this, 'phoneNumber')} />
                </Grid>
              </Grid>
              <Button variant="outlined" onClick={this.handleSubmit.bind(this)} color="primary" style={{ width: '100%', margin: '20px auto', padding: '18.5px 14px' }}>
                {language.signUpHyperLink[localStorage.getItem('lang')]}
              </Button>
            </Paper>
          </Grid>
        </Grid>
        <Loader type="fullscreen" open={this.state.loader} />
      </div >
    );
  }
}

export default Signup;