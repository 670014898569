import React, { Component } from 'react';
import Phone from '@material-ui/icons/Phone';
import Email from '@material-ui/icons/Email';
import language from '../language';


class ImgDesc extends Component {
  render() {
    return (
      <div className="foot">
        <div style={{ display: 'flex' }}>
          <h2 style={{margin: 'auto 20px'}}>{language.contactUs[localStorage.getItem('lang')]}</h2>
          <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', padding: 0 }}>
              <Phone style={{ color: 'white', marginLeft: 10 }} /><h4 style={{ marginLeft: 15 }}> 01200560982 / 01025407646</h4>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', padding: 0 }}>
              <Email style={{ color: 'white', marginLeft: 10 }} /><h4 style={{ marginLeft: 15 }}> info@koraik.com</h4>
            </div>
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', margin: 'auto 20px', justifyContent: 'space-evenly', height: '100%'}}>
          <img alt="facebook-group" src="/facebook-icon.png" style={{ maxWidth: 30, cursor: 'pointer' }} onClick={() => window.open('https://www.facebook.com/koraikEG/')}/>
          {/* <img alt="twitter-account" src='/twitter-icon.png' style={{ maxWidth: 30 }} /> */}
          <img alt="instagram-acount" src='/instagram-icon.png' style={{ maxWidth: 30, cursor: 'not-allowed' }} />
        </div>
      </div>
    );
  }
}

export default ImgDesc;