import React, { Component } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import Loader from './loader';
import axios from '../services/axios-instance';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import language from '../language';
import SinglePage from './pdf/singlePage';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import html2pdf from 'html2pdf.js';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class TableDB extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tableData: [],
      open: false,
      selectedService: {},
      loader: true,
      name: '',
      phoneNumber: '',
      monthlySub: false,
    }
  }

  componentDidMount() {
    this.getData();
  }
  getUserDetails(id) {
    let url = `${localStorage.getItem('baseURL')}/api/user/${id}`;
    let config = {
      headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    }
    axios.get(url, config)
      .then((response) => {
        this.setState({
          name: response.data.data.firstName + " " + response.data.data.lastName,
          phoneNumber: response.data.data.phoneNumber,
          monthlySub: response.data.data.monthlySub
        })
      })
      .catch((err) => console.log(err))
  }
  getData() {
    let url = `${localStorage.getItem('baseURL')}/api/services`;
    let config = {
      headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    }
    axios.get(url, config)
      .then((response) => {
        this.getUserDetails(localStorage.getItem("userId"))
        this.setState({ tableData: response.data.foundServices, loader: false })
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem("token")
          this.props.history.push("/login")
        }
      })
  }
  downloadPDF() {
    this.setState({ open: false });
    var element = document.getElementsByClassName('pdf-body')[0];
    html2pdf(element);
  }
  showServiceDetails() {
    return (
      <Dialog fullScreen open={this.state.open} onClose={() => this.setState({ open: false })} TransitionComponent={Transition}>
        <AppBar>
          <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
            <IconButton edge="start" style={{ color: 'white' }} onClick={() => this.setState({ open: false })} aria-label="close">
              <CloseIcon />
            </IconButton>
            <IconButton autoFocus style={{ color: 'white' }} onClick={() => this.downloadPDF()}>
              <GetAppIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div style={{ marginTop: 80 }}>
          <SinglePage id="singlePage" selectedService={this.state.selectedService} name={this.state.name} phoneNumber={this.state.phoneNumber}></SinglePage>
        </div>
      </Dialog>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.monthlySub ? <p>{language.youSubscribedToMonthlyService[localStorage.getItem("lang")]}</p> : null}
        {this.state.tableData ?
          <React.Fragment>
            <Loader open={this.state.loader} type="fullsection" />
            {this.state.tableData.length ?
              <div style={{ maxWidth: '100%', overflowX: 'scroll' }}>
                <Table className="services-table">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>{language.brand[localStorage.getItem("lang")]}</strong></TableCell>
                      <TableCell><strong>{language.model[localStorage.getItem("lang")]}</strong></TableCell>
                      <TableCell><strong>{language.modelYear[localStorage.getItem("lang")]}</strong></TableCell>
                      <TableCell><strong>{language.licensePlate[localStorage.getItem("lang")]}</strong></TableCell>
                      <TableCell><strong>{language.address[localStorage.getItem("lang")]}</strong></TableCell>
                      <TableCell><strong>{language.servicesRequested[localStorage.getItem("lang")]}</strong></TableCell>
                      <TableCell><strong>{language.status[localStorage.getItem("lang")]}</strong></TableCell>
                      <TableCell><strong>{language.dateCreated[localStorage.getItem("lang")]}</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.tableData.map((row) => {
                      return (
                        <TableRow style={row.status === "Done" ? { cursor: 'pointer' } : { cursor: 'auto' }} key={row._id} hover onClick={() => { row.status === "Done" ? this.setState({ open: true, selectedService: row }) : this.setState({ open: false, selectedService: {} }) }}>
                          <TableCell>{row.car.brandName}</TableCell>
                          <TableCell>{row.car.model}</TableCell>
                          <TableCell>{row.car.modelYear}</TableCell>
                          <TableCell>{row.car.licensePlate}</TableCell>
                          <TableCell>{row.userAddress}</TableCell>
                          <TableCell>
                            {row.services.length
                              && row.services.map((service, index) => {
                                if (index === row.services.length - 1) {
                                  return (service);
                                }
                                else {
                                  return (service + ' - ');
                                }
                              })}
                          </TableCell>
                          <TableCell>{row.status}</TableCell>
                          <TableCell>{moment(row.datecreated).format('YYYY-MM-DD')}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              :
              <Paper
                id="customerProfileTabsPapersWithoutPadding"
                style={{ margin: '20px auto' }}
              >
                <p
                  style={{
                    padding: 40,
                    background: "#1d3768",
                    textAlign: "center",
                    marginTop: 0,
                    color: 'white',
                    fontSize: 'large',
                    fontWeight: "bolder"
                  }}
                >
                  {language.noPrevServices[localStorage.getItem("lang")]}</p>
              </Paper>
            }
            {this.state.open && this.state.loader === false ? this.showServiceDetails() : null}
          </React.Fragment>
          :
          null}
      </React.Fragment>
    )
  }
}

export default withRouter(TableDB);