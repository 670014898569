import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { withRouter } from 'react-router-dom';
import language from '../language';

class Slider extends Component {
  navigate = () => {
    if (localStorage.getItem("token")) {
      this.props.history.push('/newService')
    } else {
      this.props.history.push('/login')
    }
  }
  render() {
    return (
      <Carousel
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={true}
        className="carousel"
      >
        <div>
          <img alt="slider-1" src={`./Sliders/${localStorage.getItem("lang")}/Slide-1.jpg`} />
          <div className="overlay-yellow" onClick={this.navigate}>
            <div className="text" style={{color: '#1d3768'}}>{language.requestKoraik[localStorage.getItem('lang')]}</div>
          </div>
        </div>
        <div>
          <img alt="slider-2" src={`./Sliders/${localStorage.getItem("lang")}/Slide-3.jpg`} />
          <div className="overlay-blue" onClick={this.navigate}>
            <div className="text" style={{color: '#fdb715'}}>{language.requestKoraik[localStorage.getItem('lang')]}</div>
          </div>
        </div>
        <div>
          <img alt="slider-3" src={`./Sliders/${localStorage.getItem("lang")}/Slide-4.jpg`} />
          <div className="overlay-yellow" onClick={this.navigate}>
            <div className="text" style={{color: '#1d3768'}}>{language.requestKoraik[localStorage.getItem('lang')]}</div>
          </div>
        </div>
        <div>
          <img alt="slider-3" src={`./Sliders/${localStorage.getItem("lang")}/Slide-2.jpg`} />
          <div className="overlay-blue" onClick={this.navigate}>
            <div className="text" style={{color: '#fdb715'}}>{language.requestKoraik[localStorage.getItem('lang')]}</div>
          </div>
        </div>
      </Carousel>
    );
  }
}
export default withRouter(Slider);

