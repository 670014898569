import React, { Component } from 'react';

class ImgDesc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      papperArray: ["favicon.ico", "favicon.ico", "01-2.png", "asf"],
    }
  }
  render() {
    return (
      <div className="img-container">
        <img alt="background" src="./strip-01.jpg" />
      </div>
    );
  }
}

export default ImgDesc;