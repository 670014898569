const language = {
  "email": {
    en: "Email",
    ar: "البريد الالكتروني"
  },
  "password": {
    en: "Password",
    ar: "كلمة السر"
  },
  "login": {
    en: "Login",
    ar: "دخول"
  },
  "signUpMessage": {
    en: "Don't have an account?",
    ar: "ليس لديك حساب؟"
  },
  "clickHere": {
    en: 'Click here',
    ar: 'اضغط هنا'
  },
  "forgotPassword": {
    en: "Forgot Password?",
    ar: 'نسيت كلمة السر؟'
  },
  "sendVEmail": {
    en: 'Send Verification E-mail',
    ar: 'ابعت كود التاكيد'
  },
  "signUpHyperLink": {
    en: "Sign Up",
    ar: "انضم الان"
  },
  "firstName": {
    en: "First Name",
    ar: "الاسم الاول"
  },
  "lastName": {
    en: "Last Name",
    ar: "الاسم الاخير"
  },
  "confirmPassword": {
    en: "Confirm Password",
    ar: "تاكيد كلمة السر"
  },
  "phoneNumber": {
    en: "Phone Number",
    ar: "رقم الهاتف"
  },
  "passwordMissing": {
    en: "Please fill the password",
    ar: "برجاء ادخال كلمة السر"
  },
  "emailError": {
    en: "Please use a valid email",
    ar: "برجاع استخدام بريد الالكتروني صحيح"
  },
  "emailMissing": {
    en: "Please fill the email",
    ar: "برجاء ادخال البريد الالكتروني"
  },
  "fillAllFieldsError": {
    en: "Please fill all the fields",
    ar: "برجاء ملي جميع الخانات"
  },
  "phoneNumberError": {
    en: "Please enter a valid phone number",
    ar: "برجاء ادخال رقم هاتف مكون من 11 رقم"
  },
  "passwordError": {
    en: "Both passwords should match & should be at least 8 characters",
    ar: "كلمتان السر يجب ان يكونا متشابهان و 8 حروف او ارقام"
  },
  "ourServices": {
    en: "Our Services",
    ar: "خدماتنا"
  },
  "ourServicesDesc": {
    en: "We provide different types of services",
    ar: "بنقدملك خدمات كثيرة"
  },
  "maintenance": {
    en: "Maintenance",
    ar: "الصيانة في الورشة"
  },
  "maintenanceDesc": {
    en: "One day maintenance to take care of your car with high quality service and spare parts, reserve your spot now.",
    ar: "للاصلاحات التي لا يمكن القيام بها خارج الورشة، سنقوم بعمل ما يلزم وجلب أفضل قطع الغيار حتى تستخدم مركبتك بأمان، احجز موعدك الأن"
  },
  "serviceAtYourPlace": {
    en: "Service at your place",
    ar: "صيانة عربيتك وانت في مكانك"
  },
  "serviceAtYourPlaceDesc": {
    en: "We provide regular/periodic services at your doorstep, saving you from the hassle of going to the service center. Don't waste your time and Order Koraik now",
    ar: "سنكون في الموعد عند محل تواجدك ( العمل ، المنزل ، النادي ، ... )، وفر وقتك ومجهودك واطلب خدمة كوريك الأن"
  },
  "technicalEvaluation": {
    en: "Technical Evaluation",
    ar: "تقييم فني"
  },
  "technicalEvaluationDesc": {
    en: "Evaluate the technical status of your Car",
    ar: "نقوم بتقييم السيارة فنياً ، وعمل تقرير بحالتها"
  },
  "repair": {
    en: "Repair",
    ar: "الاصلاح"
  },
  "repairDesc": {
    en: "We handle deep maintenance that would take more than one day at different workshops with instant updates",
    ar: "نقوم باصلاح البنود المختلفة ( ميكانيكا ، كهرباء ، سمكرة ، فيبر ، تكييف ، ... الخ ) في حالة اضطررنا لبقاء السيارة بالورشة لأكثر من يوم، مع ابلاغك بكافة تطورات الاصلاح أولاً بأول"
  },
  "subscription": {
    en: "Periodic checkup service",
    ar: "خدمة الفحص الدوري"
  },
  "subscriptionDesc": {
    en: "We do a periodic check up on your car to check the car's condition, oil level and determine any other problem in your car",
    ar: "نقوم بعمل زيارة دورية بالاتفاق مع العميل لمراجعة حالة السيارة والكشف على مستويات السوائل والمشاكل التي قد تطرأ عليها"
  },
  "contactUs": {
    en: "Contact Us",
    ar: "تواصل معنا"
  },
  "requestKoraik": {
    en: "Request Koraik",
    ar: "اطلب كوريك"
  },
  "history": {
    en: "Services history",
    ar: "تاريخ الاصلاحات"
  },
  "logOut": {
    en: "Log Out",
    ar: "خروج"
  },
  "about": {
    en: "About us",
    ar: "عن كوريك"
  },
  "brand": {
    en: "Brand",
    ar: "الماركة"
  },
  "model": {
    en: "Model",
    ar: "الموديل"
  },
  "modelYear": {
    en: "Model Year",
    ar: "سنة الصنع"
  },
  "transmission": {
    en: "Transmission",
    ar: "نوع الفتيس"
  },
  "km": {
    en: "Km",
    ar: "عداد الكم"
  },
  "cc": {
    en: "cc",
    ar: "سعة الموتور"
  },
  "promoCode": {
    en: "Promo Code",
    ar: "بروموكود"
  },
  "licensePlate": {
    en: "License Plate",
    ar: "رقم لوحة العربية"
  },
  "address": {
    en: "Address",
    ar: "العنوان"
  },
  "pickServices": {
    en: "Pick Services",
    ar: "اختار الخدمات"
  },
  "extra": {
    en: "Extra",
    ar: "خدمات اخري"
  },
  "yourCars": {
    en: "Your Cars",
    ar: "عربياتك"
  },
  "order": {
    en: "Order",
    ar: "اطلب"
  },
  "service_oilChange": {
    en: "Motor Oil Change",
    ar: "تغيير زيت الموتور"
  },
  "service_filterOilChange": {
    en: "Motor Oil Filter Change",
    ar: "تغيير فلتر زيت الموتور"
  },
  "service_airFilter": {
    en: "Clean/Change Air Filter",
    ar: "تنظيف/تغيير فلتر الهواء"
  },
  "service_acFilter": {
    en: "Clean/Change AC Filter",
    ar: "تنظيف/تغيير فلتر التكيف"
  },
  "service_oilCheckUp": {
    en: "Oil Check Up",
    ar: "مراجعة زيوت العربية"
  },
  "service_brakeLinings": {
    en: "Change Brake Linings",
    ar: "تغيير تيل الفرامل الامامي"
  },
  "service_sparkPlug": {
    en: "Change Spark Plugs",
    ar: "تغيير بوجيهات"
  },
  "service_windshieldWipers": {
    en: "Change Windshield Wipers",
    ar: "تغيير مساحات"
  },
  "service_suspensionSystemMaintenance": {
    en: "Suspension System Maintenance",
    ar: "تربيط عفشة"
  },
  "service_changeBattery": {
    en: "Change Car Battery",
    ar: "تغيير بطارية"
  },
  "service_changeCarLights": {
    en: "Change Headlight Bulbs",
    ar: "تغيير لمبات الاضائة"
  },
  "servicesRequested": {
    en: "Services Requested",
    ar: "الخدمات المطلوبة"
  },
  "status": {
    en: "Status",
    ar: "حالة الطلب"
  },
  "dateCreated": {
    en: "Date Created",
    ar: "تاريخ الطلب"
  },
  "send": {
    en: "Send",
    ar: "ابعت"
  },
  "cancel": {
    en: "Cancel",
    ar: "الغاء"
  },
  "changePassword": {
    en: "Change Password",
    ar: "تغيير كلمة السر"
  },
  "noPrevServices": {
    en: "No previous services yet! Order Koraik now",
    ar: "لا يوجد صيانات سابقة, اطلب كوريك الان"
  },
  "newCar": {
    en: "New Car",
    ar: "عربية جديدة"
  },
  "subscribeTitle": {
    en: "Subscribe in Koraik's periodic check up service",
    ar: " الاشتراك في خدمة الفحص الدوري من كوريك"
  },
  "subscribeContent": {
    en: "By subscribing in this service someone will contact you from our team to confirm the perodic checkup",
    ar: "بالاشتراك في الخدمة سيقوم احد الفريق بالاتصال بك لتحديد مواعيد الصيانة"
  },
  "confirmSubscription": {
    en: "Confirm Subscription",
    ar: "تأكيد الأشتراك"
  },
  "removeSubscription": {
    en: "Remove Subscription",
    ar: "الغاء الأشتراك"
  },
  "youSubscribedToMonthlyService": {
    en: "You are already subscribed to our periodic checkup service",
    ar: "انت مشترك في خدمة الفحص الدوري من كوريك"
  },
  "removeSub": {
    en: "You have already subscribed in our periodic checkup service",
    ar: "انت بالفعل مشترك في خدمة الفحص الدوري من كوريك"
  },
}
export default language;