import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import swal from 'sweetalert';
import Loader from './loader';
import axios from 'axios';
import language from '../language.js';

class ChangePassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      token: '',
      feedback: null,
      loading: true,
    }
  }
  componentDidMount() {
    this.sendToken();
  }
  sendToken() {
    let token = window.location.pathname.split("/").pop();
    let url = `${localStorage.getItem('baseURL')}/api/forgetPassword/${token}`;
    axios.get(url)
      .then(response => {
        this.setState({ loading: false, email: response.data.email, token })
      })
      .catch(err => {
        this.setState({ loading: false })
        swal("Error", err.response.data.message, "error")
          .then(() => this.props.history.push('/'))
      })
  }
  handleChange(field, evt) {
    this.setState({
      [field]: evt.target.value
    })
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSubmit();
    }
  }
  validate() {
    if (this.state.email) {
      if (this.state.email.includes('@') && this.state.email.includes('.')) {
        if (this.state.password === this.state.confirmPassword && this.state.password.length > 7 && this.state.confirmPassword.length > 7) {
          return;
        } else {
          return { password: language.passwordError[localStorage.getItem('lang')] }
        }
      } else {
        return { email: language.emailError[localStorage.getItem('lang')] }
      }
    } else {
      return { email: language.emailMissing[localStorage.getItem('lang')] }
    }
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    let issue = this.validate()
    if (issue) {
      swal(Object.values(issue)[0])
    } else {
      const url = `${localStorage.getItem('baseURL')}/api/user/changePassword`;
      this.setState({ loading: true })
      var obj = {
        email: this.state.email,
        password: this.state.password,
        token: this.state.token
      }
      axios.post(url, obj)
        .then((res) => {
          this.setState({ loading: false })
          swal("Success", res.data.message, "success")
          .then(()=> this.props.history.push(`/login`))
        })
        .catch((error) => {
          this.setState({ loading: false })
          swal("Error", error.response.data.message, "error")
        })
    }
  }
  render() {
    return (
      <div className="app-addpost">
        <Grid container spacing={24} justify="center" alignItems="center">
          <Grid item xs={12} md={6} style={{ padding: 40 }}>
            <Paper className="paper" style={{ padding: 40, position: 'relative' }}>
              <div className="login-logo">
                <img src="/logo.png" alt="logo" />
              </div>
              <form>
                <TextField
                  placeholder={language.email[localStorage.getItem('lang')]}
                  margin="normal"
                  variant="outlined"
                  style={{ width: '100%', marginTop: 0 }}
                  onKeyPress={(e) => this.handleKeyPress(e)}
                  onChange={this.handleChange.bind(this, 'email')}
                  value={this.state.email}
                  className="rtl"
                  disabled={true}
                />
                <TextField
                  placeholder={language.password[localStorage.getItem('lang')]}
                  margin="normal"
                  variant="outlined"
                  style={{ width: '100%' }}
                  onKeyPress={(e) => this.handleKeyPress(e)}
                  onChange={this.handleChange.bind(this, 'password')}
                  value={this.state.password}
                  type="password"
                />
                <TextField
                  placeholder={language.confirmPassword[localStorage.getItem('lang')]}
                  margin="normal"
                  variant="outlined"
                  style={{ width: '100%' }}
                  onKeyPress={(e) => this.handleKeyPress(e)}
                  onChange={this.handleChange.bind(this, 'confirmPassword')}
                  value={this.state.confirmPassword}
                  type="password"
                />
                <Button variant="outlined" onClick={(e) => this.handleSubmit(e)} size="large" style={{ width: '100%', marginTop: 15, padding: 15 }}>
                  {language.changePassword[localStorage.getItem('lang')]}
                </Button>
                {this.state.feedback && <p className="login-feedback">{this.state.feedback}</p>}
              </form>
            </Paper>
          </Grid>
        </Grid>
        <Loader type="fullscreen" open={this.state.loading} />
      </div>
    )
  }
}

export default ChangePassword