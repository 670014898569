import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import GridList from '@material-ui/core/GridList';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Paper from '@material-ui/core/Paper';
import Loader from './loader';
import swal from 'sweetalert';
import axios from '../services/axios-instance';
import language from '../language';

class Service extends Component {
  constructor(props) {
    super(props);
    this.state = {
      model: '',
      brandName: '',
      modelYear: '',
      licensePlate: '',
      transmission: '',
      cc: '',
      extraServices: '',
      address: '',
      loader: false,
      services: [],
      extra: false,
      cars: [],
      serviceCars: [],
      serviceCarFiltered: [],
      promoCode: '',
      serviceCarId: ''
    }
  }

  componentDidMount() {
    this.setState({loader: true});
    let url = `${localStorage.getItem('baseURL')}/api/cars`;
    let config = {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        // 'lang': localStorage.getItem("lang")
      }
    }
    axios.get(url, config)
      .then(res => {
        this.setState({ cars: res.data.cars })
      })
      .catch(err => {
        console.log(err);
      })
    let url2 = `${localStorage.getItem('baseURL')}/api/getAllServiceCars`;
    axios.get(url2, config)
      .then(res => {
        this.setState({ serviceCars: res.data.data, serviceCarFiltered: this.formatCars(res.data.data), loader: false })
      })
      .catch(err => {
        console.log(err);
      })
  }

  services() {
    const services = [
      language.service_oilChange[localStorage.getItem('lang')],
      language.service_filterOilChange[localStorage.getItem('lang')],
      language.service_airFilter[localStorage.getItem('lang')],
      language.service_acFilter[localStorage.getItem('lang')],
      language.service_oilCheckUp[localStorage.getItem('lang')],
      language.service_brakeLinings[localStorage.getItem('lang')],
      language.service_sparkPlug[localStorage.getItem('lang')],
      language.service_windshieldWipers[localStorage.getItem('lang')],
      language.service_suspensionSystemMaintenance[localStorage.getItem('lang')],
      language.service_changeBattery[localStorage.getItem('lang')],
      language.service_changeCarLights[localStorage.getItem('lang')]
    ];
    return services;
  }
  getYears(startYear) {
    var currentYear = new Date().getFullYear(), years = [];
    startYear = startYear || 1990;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years;
  }
  handleChange(field, event) {
    if (event.target.value.hasOwnProperty("modelYear")) {
      let car = event.target.value;
      let carData = this.state.serviceCars.find(serviceCar => serviceCar._id === event.target.value.serviceCarId)
      this.setState({
        brandName: carData.brand[localStorage.getItem("lang")],
        model: carData.model[localStorage.getItem("lang")],
        modelYear: car.modelYear,
        licensePlate: car.licensePlate,
        transmission: car.transmission,
        cc: car.cc,
        // km: car.km
      })
    } else if (field === 'brandName') {
      this.setState({
        brandName: event.target.value,
        model: '',
        modelYear: '',
        licensePlate: '',
        transmission: '',
        cc: '',
      })
    } else if (field === 'model') {
      let id = (this.state.serviceCarFiltered[this.state.brandName].find(car => car.model[localStorage.getItem("lang")] === event.target.value)._id)
      this.setState({
        [field]: event.target.value,
        serviceCarId: id,
      })
    } else {
      this.setState({
        [field]: event.target.value,
      })
    }
  }
  handleChangeCheck = name => event => {
    if (this.state.services.includes(name)) {
      this.state.services.pop(name);
    }
    else {
      this.state.services.push(name);
    }
  };
  validate() {
    if (this.state.brandName === "" || this.state.model === "" || this.state.modelYear === "" || this.state.licensePlate === "" || this.state.transmission === "" || this.state.cc === "" || this.state.km === "" || this.state.address === "") {
      return "Please fill all the required data"
    } else if (!this.state.services.length && !this.state.extra) {
      return "Please choose at least one service"
    } else {
      return

    }
  }
  handleSubmit() {
    let issue = this.validate()
    if (issue) {
      swal(issue)
    } else {
      let url = `${localStorage.getItem('baseURL')}/api/services`;
      this.setState({ loader: true })
      const obj = {
        userId: localStorage.getItem('userId'),
        brandName: this.state.brandName,
        model: this.state.model,
        modelYear: this.state.modelYear,
        licensePlate: this.state.licensePlate,
        //delete
        transmission: this.state.transmission,
        services: this.state.services,
        cc: this.state.cc,
        km: this.state.km,
        extraServices: this.state.extraServices,
        userAddress: this.state.address,
        promoCode: this.state.promoCode,
        serviceCarId: this.state.serviceCarId
        // status: "In Review"
      };
      let config = {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      }
      axios.post(url, obj, config)
        .then((response) => {
          this.setState({ loader: false });
          swal('Order Placed', 'Your order was placed successfully', 'success')
            .then(() => this.props.history.push('/'))

        }).catch((error) => {
          this.setState({ loader: false });
          if (error.response.status === 401) {
            localStorage.removeItem("token")
            this.props.history.push("/login")
          }
          swal('Error', error, 'error')
        })
    }
  }
  formatCars(arr) {
    return arr.reduce((r, a) => {
      r[a.brand[localStorage.getItem("lang")]] = r[a.brand[localStorage.getItem("lang")]] || [];
      r[a.brand[localStorage.getItem("lang")]].push(a);
      return r;
    }, Object.create(null))
  }
  render() {
    return (
      <FormControl>
        <Paper style={{ marginTop: 20 }}>
          <h1>{language.requestKoraik[localStorage.getItem('lang')]}</h1>
          <Grid container justify="center" spacing={24} style={{ width: '50%', margin: '0px auto' }}>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel>{language.brand[localStorage.getItem('lang')]} *</InputLabel>
                <Select
                  id="brandName"
                  value={this.state.brandName}
                  name="brandName"
                  type="textarea"
                  required={true}
                  input={<OutlinedInput labelWidth={50} name="brand" />}
                  onChange={(e) => { this.handleChange('brandName', e) }}>
                  <MenuItem disabled value="">{language.brand[localStorage.getItem('lang')]}</MenuItem>
                  <MenuItem disabled value="">{language.yourCars[localStorage.getItem('lang')]}:</MenuItem>
                  {this.state.cars.length && this.state.serviceCars.length?
                    this.state.cars.map((car, key) => {
                      let carData = this.state.serviceCars.find(serviceCar => serviceCar._id === car.serviceCarId)
                      return (<MenuItem key={key} value={car}>{carData.brand[localStorage.getItem("lang")] + "-" + carData.model[localStorage.getItem("lang")] + "-" + car.modelYear}</MenuItem>)
                    })
                    : null}
                  <MenuItem disabled value="">{language.newCar[localStorage.getItem('lang')]}:</MenuItem>
                  {Object.keys(this.formatCars(this.state.serviceCars)).map((car, key) => <MenuItem key={key} value={car}>{car}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel>{language.model[localStorage.getItem('lang')]} *</InputLabel>
                <Select
                  id="model"
                  disabled={!Boolean(this.state.brandName)}
                  value={this.state.model}
                  name="model"
                  type="textarea"
                  required={true}
                  input={<OutlinedInput labelWidth={50} name="model" />}
                  onChange={(e) => { this.handleChange('model', e) }}>
                  {this.state.brandName && !this.state.brandName.includes('-') ? this.state.serviceCarFiltered[this.state.brandName].map((car, key) => <MenuItem key={key} value={car.model[localStorage.getItem('lang')]}>{car.model[localStorage.getItem('lang')]}</MenuItem>) : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel>{language.modelYear[localStorage.getItem('lang')]} *</InputLabel>
                <Select
                  value={this.state.modelYear}
                  name="modelYear"
                  type="number"
                  required={true}
                  input={<OutlinedInput labelWidth={85} name="model year" />}
                  onChange={(e) => { this.handleChange('modelYear', e) }}>
                  {this.getYears().map((year, key) => {
                    return (<MenuItem value={year} key={key}>{year}</MenuItem>)
                  })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel>{language.transmission[localStorage.getItem('lang')]} *</InputLabel>
                <Select
                  value={this.state.transmission}
                  name="transmission"
                  type="text"
                  required={true}
                  input={<OutlinedInput labelWidth={100} name="Transmission" />}
                  onChange={(e) => { this.handleChange('transmission', e) }}>
                  <MenuItem value='manual'>Manual</MenuItem>
                  <MenuItem value='automatic'>Automatic</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="cc"
                label={language.cc[localStorage.getItem('lang')]}
                value={this.state.cc}
                name="cc"
                variant="outlined"
                type="number"
                required={true}
                onChange={this.handleChange.bind(this, 'cc')}
                style={{ width: '100%' }}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="km"
                label={language.km[localStorage.getItem('lang')]}
                value={this.state.km}
                name="km"
                variant="outlined"
                type="number"
                required={true}
                onChange={this.handleChange.bind(this, 'km')}
                style={{ width: '100%' }}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="licensePlate"
                label={language.licensePlate[localStorage.getItem('lang')]}
                value={this.state.licensePlate}
                variant="outlined"
                name="License Plate"
                type="text"
                required={true}
                onChange={this.handleChange.bind(this, 'licensePlate')}
                style={{ width: '100%' }}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="promoCode"
                label={language.promoCode[localStorage.getItem('lang')]}
                value={this.state.promoCode}
                variant="outlined"
                name="Promo Code"
                type="text"
                onChange={this.handleChange.bind(this, 'promoCode')}
                style={{ width: '100%' }}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id="address"
                label={language.address[localStorage.getItem('lang')]}
                value={this.state.address}
                variant="outlined"
                name="Address"
                type="text"
                required={true}
                onChange={this.handleChange.bind(this, 'address')}
                style={{ width: '100%' }}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={12} style={{ marginBottom: 20 }}>
              <FormLabel component="legend">{language.pickServices[localStorage.getItem('lang')]} *</FormLabel>
              <FormGroup style={{ alignItems: 'center', overflow: 'hidden' }}>
                <GridList cellHeight={30} cols={4} className="services-list">
                  {
                    this.services().map((service, key) => {
                      return (<FormControlLabel
                        key={key}
                        control={<Checkbox checked={this.state.service} value={service} onChange={this.handleChangeCheck(service)} />}
                        label={service}
                        className="service-check"
                      />);
                    })
                  }
                </GridList>
              </FormGroup>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className="extra-services">
              <FormControlLabel
                control={<Checkbox color="secondary" checked={this.state.extra} onChange={() => { this.setState({ extra: !this.state.extra }) }} />}
                label={language.extra[localStorage.getItem('lang')]}
                className="extra-margin"
              />
              <TextField
                className="extra-services-text"
                label={language.extra[localStorage.getItem('lang')]}
                variant="outlined"
                disabled={!this.state.extra}
                name="extra"
                type="text"
                multiline
                rowsMax={4}
                rows={3}
                onChange={this.handleChange.bind(this, 'extraServices')}
              />
            </div>
          </Grid>
          <Button variant="outlined" onClick={this.handleSubmit.bind(this)} color="primary" style={{ width: '25%', margin: '0px auto 20px auto' }}>
            {language.order[localStorage.getItem('lang')]}
          </Button>
          <Loader open={this.state.loader} type="fullsection" />
        </Paper>
      </FormControl>
    );
  }
}

export default Service;