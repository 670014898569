import React from 'react';
import Page from './page';
import './pdf.css';

function generateTotal(array) {
  let total = 0;
  array.forEach(item => {
    total = total + Number(item.price)
  })
  return total;
}
const SinglePage = (props) => (
  <Page singleMode={true} id={props.id}>
    <div className="pdf-body">
      <div className="header">
        <div className="logo-container">
          <img
            alt="logo"
            className="image"
            src="/logo.png"
          />
          <img
            alt="logo"
            className="logoFont"
            src="/blue-logo-ar.png"
          />
        </div>
        <div className="details-pdf">
          <p className="text-pdf">
            <span className="text-key">فاتورة</span>
          </p>
          <p className="text-pdf">
            <span className="text-key">الاسم </span>{props.name}
          </p>
          <p className="text-pdf">
            <span className="text-key">رقم التليفون </span>{props.phoneNumber}
          </p>
          <p className="text-pdf">
            <span className="text-key"> العنوان </span>{props.selectedService.userAddress}
          </p>
          <p className="text-pdf">
            <span className="text-key">نوع العربية </span>{props.selectedService.car.brandName + "-" + props.selectedService.car.model + "-" + props.selectedService.car.modelYear}
          </p>
          <p className="text-pdf">
            <span className="text-key">رقم اللوحة </span>{props.selectedService.car.licensePlate}
          </p>
        </div>
      </div>
      <div className="table">
        <p className="tableTitle">قطع الغيار</p>
        <div className="tableHeader">
          <p className="tableText">اسم القطعة</p>
          <p className="tableText">سعر القطعة</p>
          <p className="tableText">الكمية</p>
          <p className="tableText">الاجمالي</p>
        </div>
        {props.selectedService.spareParts.map((sparePart, key) => {
          return (
            <div className="tableRow" key={key}>
              <p className="tableText">{sparePart.item}</p>
              <p className="tableText">{sparePart.price}</p>
              <p className="tableText">{sparePart.quantity}</p>
              <p className="tableText">{Number(sparePart.price) * Number(sparePart.quantity)}</p>
            </div>
          )
        })}
      </div>
      <div className="subTotaldiv">
        <p className="text-pdf">الاجمالي {generateTotal(props.selectedService.spareParts)} جنيها</p>
      </div>
      <div className="breakLine"></div>
      <div className="table">
        <p className="tableTitle">الخدمات</p>
        <div className="tableHeader">
          <p className="tableText">اسم الخدمة</p>
          <p className="tableText">الاجمالي</p>
        </div>
        {props.selectedService.maintenanceDone.map((maintenance, key) => {
          return (
            <div className="tableRow" key={key}>
              <p className="tableText">{maintenance.item}</p>
              <p className="tableText">{maintenance.price}</p>
            </div>
          )
        })}
      </div>
      <div className="subTotaldiv">
        <p className="text-pdf"> الاجمالي {generateTotal(props.selectedService.maintenanceDone)} جنيها</p>
      </div>
      <div className="breakLine"></div>
      <div className="grandTotal">
        <p className="grandTotalText"> الاجمالي {generateTotal(props.selectedService.maintenanceDone) + generateTotal(props.selectedService.spareParts)} جنيها</p>
      </div>
      <div className="notes">
        <h5>الملحظات</h5>
        <p>{props.selectedService.notes}</p>
      </div>
    </div>
   </Page>
);

export default SinglePage;