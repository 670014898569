import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withRouter } from 'react-router-dom';
import axios from '../services/axios-instance';
import swal from 'sweetalert';
import Loader from '../components/loader';
import language from '../language';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

class Icons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconsArray:
        [{ title: language.serviceAtYourPlace[localStorage.getItem('lang')], desc: language.serviceAtYourPlaceDesc[localStorage.getItem('lang')] },
        { title: language.maintenance[localStorage.getItem('lang')], desc: language.maintenanceDesc[localStorage.getItem('lang')] },
        { title: language.repair[localStorage.getItem('lang')], desc: language.repairDesc[localStorage.getItem('lang')] },
        { title: language.subscription[localStorage.getItem('lang')], desc: language.subscriptionDesc[localStorage.getItem('lang')] }],
      openSubscriptionDialog: false,
      loader: false
    }
  }

  componentDidMount() {
    this.getUserDetails()
  }
  getUserDetails() {
    let url = `${localStorage.getItem('baseURL')}/api/user/${localStorage.getItem("userId")}`;
    let config = {
      headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    }
    axios.get(url, config)
      .then((response) => {
        this.setState({
          name: response.data.data.firstName + " " + response.data.data.lastName,
          phoneNumber: response.data.data.phoneNumber,
          email: response.data.data.email,
          monthlySub: response.data.data.monthlySub
        })
      })
      .catch((err) => console.log(err))
  }
  isInViewport(offset = 0) {
    if (!this.yourElement) return false;
    const top = this.yourElement.getBoundingClientRect().top;
    return (top + offset) >= 0 && (top - offset) <= window.innerHeight;
  }
  navigate = () => {
    if (localStorage.getItem("token")) {
      this.props.history.push('/newService')
    } else {
      this.props.history.push('/login')
    }
  }
  handleIconClick(key) {
    if (key === 3) {
      if (localStorage.getItem("token")) {
        this.setState({ openSubscriptionDialog: true })
      } else {
        this.props.history.push('/login')
      }
    } else {
      this.navigate();
    }
  }
  handleClose = () => {
    this.setState({ openSubscriptionDialog: false })
  }
  handleSubmitMonthlySub = () => {
    this.setState({ loader: true })
    let url = `${localStorage.getItem('baseURL')}/api/user/subscribe`;
    const obj = {
      'email': this.state.email.toLowerCase(),
    };
    axios.post(url, obj)
      .then((response) => {
        console.log(response.response)
        this.setState({ loader: false })
        this.handleClose();
        swal('Success', response.data.message, 'success')
          .then(() => window.location.reload())
      }).catch((error) => {
        console.log(error.response)
        this.setState({ loader: false })
        this.handleClose();
        swal('Error', error.response.data.message, 'error');
      })
  }
  render() {
    return (
      <div className={styles.root} style={{ margin: '20px 0px', padding: '0px 50px' }}>
        <h1>{language.ourServices[localStorage.getItem('lang')]}</h1>
        <h3>{language.ourServicesDesc[localStorage.getItem('lang')]}</h3>
        <Button variant="contained" color="primary" onClick={this.navigate} size="large" style={{ marginBottom: 10, color: '#fff' }} className="order-koraik-width"> {language.requestKoraik[localStorage.getItem('lang')]}</Button>
        <Grid container spacing={24} justify="center">
          {this.state.iconsArray.map((icon, key) => {
            return (
              <Slide direction="up" in={true} key={key}>
                <Grid item md={6} sm={6} xs={12} key={key} ref={(el) => this.yourElement = el}>
                  <img alt={icon.title} src={`/Service-Icons-SVG/icon${key + 1}.svg`} style={{ marginTop: 10, maxWidth: 300, maxHeight: 280, cursor: 'pointer' }} onClick={() => this.handleIconClick(key)} />
                  <div>
                    <h3 style={{ fontSize: 'x-large' }}>{this.state.iconsArray[key].title}</h3>
                    <p style={{ margin: 'auto', maxWidth: 550 }}>{this.state.iconsArray[key].desc}</p>
                  </div>
                </Grid>
              </Slide>
            )
          })}
        </Grid>
        <Dialog open={this.state.openSubscriptionDialog}>
          <Loader open={this.state.loader} />
          <DialogTitle>{language.subscribeTitle[localStorage.getItem('lang')]}</DialogTitle>
          <DialogContent>
            {this.state.monthlySub ? language.removeSub[localStorage.getItem('lang')] : language.subscribeContent[localStorage.getItem('lang')]}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              {language.cancel[localStorage.getItem('lang')]}
            </Button>
            <Button onClick={this.handleSubmitMonthlySub} color="primary">
              {this.state.monthlySub ? language.removeSubscription[localStorage.getItem('lang')] : language.confirmSubscription[localStorage.getItem('lang')]}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(Icons);