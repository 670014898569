import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import MoreIcon from '@material-ui/icons/MoreVert';
import swal from 'sweetalert';
import Loader from './loader';
import language from '../language';

const styles = theme => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      mobileMoreAnchorEl: null,
      loader: false,
    };
  }

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };
  handleClickRequest() {

  }
  logOut() {
    swal({
      title: "Alert!",
      text: "Are you sure you want to log out?",
      className: "confirmationTrip",
      buttons: {
        cancel: {
          text: "No",
          value: null,
          visible: true,
          className: "",
          closeModal: true
        },
        confirm: {
          text: "Yes",
          value: true,
          visible: true,
          className: "",
          closeModal: true
        }
      }
    }).then(success => {
      this.setState({ loading: true })
      localStorage.removeItem('token');
      this.props.history.push('/');
      window.location.reload();
    })
  }
  navigate = () => {
    this.handleMobileMenuClose()
    if (localStorage.getItem("token")) {
      this.props.history.push('/newService')
    } else {
      this.props.history.push('/login')
    }
  }
  changeLangauge() {
    this.handleMobileMenuClose();
    this.setState({loader: true})
    if (window.localStorage.getItem("lang") === 'ar') {
      window.localStorage.setItem("lang", 'en')
      window.location.reload()
    } else {
      window.localStorage.setItem("lang", 'ar')
      window.location.reload()
    }
  }

  render() {
    const { mobileMoreAnchorEl } = this.state;
    const { classes } = this.props;
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMenuClose}
      >
        {localStorage.getItem("token") ?<MenuItem><Button style={{ fontWeight: 'bolder', color: '#1d3768' }} disabled>Hi, {localStorage.getItem("firstName")}</Button></MenuItem>:null}
        <MenuItem>
          <Button color="inherit" style={{ fontWeight: 'bolder', width: '100%' }} onClick={() => this.changeLangauge()}> {(window.localStorage.getItem("lang") === 'ar')? 'English':'العربية'}</Button>
        </MenuItem>
        <MenuItem className="menu-button">
          <Button color="inherit" onClick={() => { this.props.history.push('/'); this.handleMobileMenuClose() }}>{language.about[localStorage.getItem('lang')]}</Button>
        </MenuItem>
        <MenuItem>
          <Button color="inherit" onClick={() => { this.props.history.push('/'); this.handleMobileMenuClose(); window.scrollTo(0, document.body.scrollHeight); }}>{language.contactUs[localStorage.getItem('lang')]}</Button>
        </MenuItem>
        <MenuItem>
          <Button color="inherit" onClick={this.navigate}>{language.requestKoraik[localStorage.getItem('lang')]}</Button>
        </MenuItem>
        {localStorage.getItem("token") ? <MenuItem><Button color="inherit" onClick={() => this.props.history.push('/history')}>History</Button></MenuItem> : null}
        {localStorage.getItem("token") ? <MenuItem><Button color="inherit" onClick={() => { this.logOut() }}>Log out</Button></MenuItem> : null}
      </Menu>
    );

    return (
      <div className={classes.root}>
        <Loader open={this.state.loader} type="fullscreen" size="700"></Loader>
        <AppBar position="static" style={{ backgroundColor: '#fffff2' }}>
          <Toolbar>
            {/* <Typography className={classes.title} variant="h6" color="inherit" noWrap>
              Koraik
            </Typography> */}
            <div style={{ width: 120, height: 70, display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.props.history.push('/')}>
              {/* <img alt="logo" src="/white-logo.png" /> */}
              <img alt="logo" src="/logo.png" style={(window.localStorage.getItem("lang") === 'ar')? {height: '60%', marginLeft: 10}: { height: '60%', marginRight: 10 }} />
              <img alt="logo-text" src={(window.localStorage.getItem("lang") === 'ar')? "/blue-logo-ar.svg":"/blue-logo.svg"} style={{ width: '100%', height: '100%' }} />
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {localStorage.getItem("token") ?<Button style={{ fontWeight: 'bolder', color: '#1d3768' }} disabled>Hi, {localStorage.getItem("firstName")}</Button>:null}
              <Button color="inherit" style={{ fontWeight: 'bolder' }} onClick={() => this.changeLangauge()}> {(window.localStorage.getItem("lang") === 'ar')? 'English':'العربية'}</Button>
              <Button color="inherit" style={{ fontWeight: 'bolder' }} onClick={() => { this.props.history.push('/'); window.scrollTo(0, document.body.scrollHeight / 4) }}>{language.about[localStorage.getItem('lang')]}</Button>
              <Button color="inherit" style={{ fontWeight: 'bolder' }} onClick={() => { this.props.history.push('/'); setTimeout(window.scrollTo(0, document.body.scrollHeight), 2000) }}>{language.contactUs[localStorage.getItem('lang')]}</Button>
              <Button color="inherit" style={{ fontWeight: 'bolder' }} onClick={this.navigate}>{language.requestKoraik[localStorage.getItem('lang')]}</Button>
              {localStorage.getItem("token") ? <Button color="inherit" style={{ fontWeight: 'bolder' }} onClick={() => this.props.history.push('/history')}>{language.history[localStorage.getItem('lang')]}</Button> : null}
              {localStorage.getItem("token") ? <Button color="inherit" style={{ fontWeight: 'bolder' }} onClick={() => { this.logOut() }}>{language.logOut[localStorage.getItem('lang')]}</Button> : null}
            </div>
            <div className={classes.sectionMobile}>
              <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} >
                <MoreIcon className="navbar-text" />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Loader type="fullscreen" open={this.state.loading} />
        {/* {renderMenu} */}
        {renderMobileMenu}
      </div>
    );
  }
}

Navbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navbar);