import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import swal from 'sweetalert';
import Loader from './loader';
import axios from 'axios';
import language from '../language.js';

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      feedback: null,
      loading: false,
      openForgotPasswordDialog: false,
      forgotPasswordEmail: '',
      displayError: false
    }
  }

  handleChange(field, evt) {
    this.setState({
      [field]: evt.target.value
    })
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSubmit();
    }
  }
  validate() {
    if (this.state.email) {
      if (this.state.email.includes('@') && this.state.email.includes('.')) {
        if (this.state.password) {
          return;
        } else {
          return { password: language.passwordMissing[localStorage.getItem('lang')] }
        }
      } else {
        return { email: language.emailError[localStorage.getItem('lang')] }
      }
    } else {
      return { email: language.emailMissing[localStorage.getItem('lang')] }
    }
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    let issue = this.validate()
    if (issue) {
      swal(Object.values(issue)[0])
    } else {
      const url = `${localStorage.getItem('baseURL')}/api/user/login`;
      this.setState({ loading: true })
      var obj = {
        email: this.state.email.toLowerCase(),
        password: this.state.password
      }
      axios.post(url, obj)
        .then((res) => {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("firstName",res.data.firstName)
          localStorage.setItem("userId",res.data.userId)
          this.setState({ loading: false })
          this.props.history.push(`/`);
        })
        .catch((error) => {
          this.setState({ loading: false })
          swal("Error", error.response.data.message, "error")
        })
    }
  }
  handleClose = () => {
    this.setState({ openForgotPasswordDialog: false })
  }
  sendEmail = () => {
    if (this.state.forgotPasswordEmail === "" || !this.state.forgotPasswordEmail.includes('@') || !this.state.forgotPasswordEmail.includes('.')) {
      this.setState({ displayError: true })
    }
    else {
      this.setState({ loading: true })
      let url = `${localStorage.getItem('baseURL')}/api/forgetPassword`;
      let obj = { email: this.state.forgotPasswordEmail }
      axios.post(url, obj)
        .then(res => {
          this.setState({ loading: false, openForgotPasswordDialog: false })
          swal("Success", res.data.message, "success")
            .then(() => this.props.history.push('/'))
        })
        .catch(err => {
          this.setState({ loading: false, openForgotPasswordDialog: false })
          swal("Error", err.response.data.message, "error")
            .then(() => this.props.history.push('/'))
        })
    }
  }
  render() {
    return (
      <div>
        <Grid container spacing={24} justify="center" style={{margin: 0, width: '100%' }}>
          <Grid item xs={12} md={6} style={{ padding: 40}}>
            <Paper className="paper" style={{ padding: 40, position: 'relative' }}>
              <div className="login-logo">
                <img src="/logo.png" alt="logo" />
              </div>
              <form>
                <TextField
                  placeholder={language.email[localStorage.getItem('lang')]}
                  margin="normal"
                  variant="outlined"
                  style={{ width: '100%', marginTop: 0 }}
                  onKeyPress={(e) => this.handleKeyPress(e)}
                  onChange={this.handleChange.bind(this, 'email')}
                  value={this.state.email}
                  className="rtl"
                />
                <TextField
                  placeholder={language.password[localStorage.getItem('lang')]}
                  margin="normal"
                  variant="outlined"
                  style={{ width: '100%' }}
                  onKeyPress={(e) => this.handleKeyPress(e)}
                  onChange={this.handleChange.bind(this, 'password')}
                  value={this.state.password}
                  type="password"
                />
                <Button variant="outlined" onClick={(e) => this.handleSubmit(e)} size="large" style={{ width: '100%', marginTop: 15, padding: 15 }}>
                  {language.login[localStorage.getItem('lang')]}
                </Button>
                {this.state.feedback && <p className="login-feedback">{this.state.feedback}</p>}
              </form>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>{language.signUpMessage[localStorage.getItem('lang')]}
                  <button onClick={() => this.props.history.push('/signup')}
                    style={{
                      background: "none",
                      color: '#344c84',
                      border: "none",
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      font: "inherit"
                    }}> {language.signUpHyperLink[localStorage.getItem('lang')]}</button>
                </span>
                <span>{language.forgotPassword[localStorage.getItem('lang')]}
                  <button onClick={() => this.setState({ openForgotPasswordDialog: true })}
                    style={{
                      background: "none",
                      color: '#344c84',
                      border: "none",
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      font: "inherit"
                    }}> {language.clickHere[localStorage.getItem('lang')]}</button>
                </span>
              </div>
            </Paper>
          </Grid>
        </Grid>

        <Dialog open={this.state.openForgotPasswordDialog} onClose={this.handleClose} style={localStorage.getItem('lang') === 'ar'? {direction: 'rtl'}: {direction: 'ltr'}}>
          <DialogTitle>{language.sendVEmail[localStorage.getItem('lang')]}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              variant="outlined"
              placeholder={language.email[localStorage.getItem('lang')]}
              type="email"
              fullWidth
              style={{marginTop: 10}}
              onChange={e => this.setState({ forgotPasswordEmail: e.target.value })}
            />
            {this.state.displayError ? <p style={{ color: 'red' }}>{language.emailError[localStorage.getItem('lang')]}</p> : null}
            <Loader type="fullscreen" open={this.state.loading} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              {language.cancel[localStorage.getItem('lang')]}
            </Button>
            <Button onClick={this.sendEmail} color="primary">
              {language.send[localStorage.getItem('lang')]}
            </Button>
          </DialogActions>
        </Dialog>

        <Loader type="fullscreen" open={this.state.loading} />
      </div>
    )
  }
}

export default Login