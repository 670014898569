import React, { Component } from 'react';
import Slider from './slider';
import Icons from './icons';
import ImgDesc from './ImgDesc';
import Footer from './footer';
import { Helmet } from "react-helmet";
import { withRouter } from 'react-router-dom';

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgArray: ["favicon.ico", "01-1.png", "01-2.png"],
      index: 0
    }
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Koraik - كوريك</title>
          <meta charSet="utf-8" />
          <meta name="description" content="koraik كوريك تصليح عربيات سيارات صيانة دورية صيانة عربية سيارة maintenance car service" />
          <meta name="keywords" content="koraik,maintenance,car,service,كوريك,تصليح,عربيات,سيارات,صيانة,دورية,صيانة,عربية,سيارة" />
          <link rel="canonical" href="https://koraik.com" />
        </Helmet>
        <Slider />
        <Icons />
        <ImgDesc />
        <Footer />
      </React.Fragment>
    );
  }
}

export default withRouter(Landing);