import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TableDB from './tableDB';
import language from '../language';

class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {
    return (
      <div style={{ paddingTop: 30 }}> 
        <Button
          variant="outlined"
          color="primary"
          className="order-koraik-width"
          onClick={() => this.props.history.push('/newservice')}>
          {language.requestKoraik[localStorage.getItem("lang")]}</Button>
        <TableDB />
      </div >
    )
  }

}

export default History;