import React, { Component } from 'react';
import Loader from './loader';
import axios from 'axios';
import swal from 'sweetalert';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import language from '../language.js';

class Confirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      openDialog: false,
      email: ""
    }
  }
  componentDidMount() {
    this.sendToken();
  }
  sendToken() {
    let token = window.location.pathname.split("/").pop();
    let url = `${localStorage.getItem('baseURL')}/api/confirmation/${token}`;
    axios.get(url)
      .then(response => {
        this.setState({ loading: false })
        swal("Success", response.data.message, "success")
          .then(() => this.props.history.push('/login'))
      })
      .catch(err => {
        this.setState({ loading: false })
        if (err.response.data.resend) {
          swal("Error", err.response.data.message, "error").then(() => this.setState({ openDialog: true }))
        }
        else {
          swal("Error", err.response.data.message, "error")
            .then(() => this.props.history.push('/'))
        }
      })
  }
  handleClose = () => {
    this.setState({ openDialog: false }, () => this.props.history.push('/'))
  }
  sendEmail = () => {
    if (this.state.email === "" || !this.state.email.includes('@') || !this.state.email.includes('.')) {
      this.setState({ displayError: true })
    }
    else {
      this.setState({ loading: true })
      let url = `${localStorage.getItem('baseURL')}/api/resendToken`;
      let obj = { email: this.state.email }
      axios.post(url, obj)
        .then(res => {
          this.setState({ loading: false, openDialog: false })
          swal("Success", res.data.message, "success")
            .then(() => this.props.history.push('/'))
        })
        .catch(err => {
          this.setState({ loading: false, openDialog: false })
          swal("Error", err.response.data.message, "error")
            .then(() => this.props.history.push('/'))
        })
    }
  }
  render() {
    return (
      <React.Fragment>
        <Loader type="fullscreen" open={this.state.loading} />
        <Dialog open={this.state.openDialog} onClose={this.handleClose}>
          <DialogTitle>{language.sendVEmail[localStorage.getItem('lang')]}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              placeholder={language.email[localStorage.getItem('lang')]}
              type="email"
              fullWidth
              onChange={e => this.setState({ email: e.target.value })}
            />
            {this.state.displayError ? <p style={{ color: 'red' }}>{language.emailError[localStorage.getItem('lang')]}</p> : null}
            <Loader type="fullscreen" open={this.state.loading} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              {language.cancel[localStorage.getItem('lang')]}
            </Button>
            <Button onClick={this.sendEmail} color="primary">
              {language.send[localStorage.getItem('lang')]}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default Confirmation;